/* eslint-disable
  jsx-a11y/click-events-have-key-events,
  jsx-a11y/no-static-element-interactions
*/

import React from "react"
import { Container, Row, Col, Modal, Button } from "react-bootstrap"
import rehypeReact from "rehype-react"
import { Link, graphql } from "gatsby"
import { css } from "@emotion/core"

import PageContent from "../components/page-content"
import Layout, { bp } from "../components/layout"
import TopCover from "../components/top-cover"
import Banner from "../components/banner"
// import OverlayGreen from '../components/overlay-green';

const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler;

const createKeywordArticles = (md, keyword) => {
  const articles = {};
  let stack = null;
  const titles = [];
  let ignoreUntilNext = false;
  md.children.forEach(el => {
    const { type, tagName } = el
    switch (type) {
      case "element":
        if (tagName === "h2") {
          ignoreUntilNext = false
          const title = el.children[0].value
          if (title.startsWith("//")) {
            ignoreUntilNext = true
            return
          }
          titles.push(title)
          stack = []
          articles[title] = stack
        } else {
          if (stack === null || ignoreUntilNext) {
            return
          }
          stack.push(el)
        }
        break
      case "text":
        if (stack === null || ignoreUntilNext) {
          return
        }
        stack.push(el)
        break
      default:
        break
    }
  });
  Object.entries(articles).forEach(([title, stack]) => {
    articles[title] = renderAst({
      type: "root",
      children: stack,
    })
  });
  return {
    keywordTitles: titles,
    keywordArticles: articles,
  };
};

const KeywordModal = props => {
  const { keyword, article } = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      css={css`
        .modal-content {
          padding: 30px 30px;
          border-radius: 0;
        }
      `}
    >
      <Modal.Header
        closeButton
        css={css`
          border-bottom: none;
        `}
      >
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="green-border-left"
        >
          {keyword}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{article}</Modal.Body>
    </Modal>
  );
};

export default ({ data }) => {
  const [numNews, setNumNews] = React.useState(6);

  const [modalShow, setModalShow] = React.useState(false);
  const [selectedKeyword, setSelectedKeyword] = React.useState(null);

  const { keywordTitles, keywordArticles } = createKeywordArticles(
    data.markdownRemark.htmlAst
  );

  React.useEffect(() => {
    const s = document.createElement("script")
    s.setAttribute("src", "https://platform.twitter.com/widgets.js")
    document.body.appendChild(s)
  }, []);

  const maxNews = data.allNewsCsv.nodes.filter(
    line => line[0] !== "#"
  ).length;

  return (
    <Layout>
      <TopCover image="top-cover.jpg" />

      <PageContent>

        <div
          css={css`
            width: 100%;
            margin: 0 0 50px 0;
            padding: 0;
            text-align: center;
            font-size: 16px;
          `}
        >
          <a href="/brochure_general-intro_ver.2024-1_compressed.pdf" rel="noreferrer noopener nofollow" target="_blank">
            <img
              src="brochure-thumb.jpg"
              css={css`
                width: 100%;
                border: 1px solid #00B45A;
              `}
            ></img>
            <br />
            パンフレット ダウンロード（PDF）
          </a>
        </div>

        <Banner
            link="https://sounding.city/"
            image="banner_sounding_city_coton_web.png"
            linkAttr={{
              target: '_blank'
            }}
          />
        <Banner
          link="https://www.geidai.ac.jp/news/20220406111974.html"
          image="banner-venture.jpg"
          linkAttr={{
            target: '_blank'
          }}
        />

        <Banner
          link="https://note.com/coton_tech/"
          image="banner-interview.jpg"
          linkAttr={{
            target: '_blank'
          }}
        />

        <h2
          css={css`
            margin-bottom: 35px;
          `}
        >
          news
        </h2>
        <Container fluid className="d-flex flex-wrap">
          <Row
            css={css`
              margin-right: -30px;
            `}
          >
            {(() => {
              let count = 0;
              return data.allNewsCsv.nodes.map(({ id, date, html }) => {
                if (date[0] === "#") {
                  return <></>
                }
                const hidden = count >= numNews;
                count += 1;
                return (
                  <Col
                    key={id}
                    md={6}
                    className="green-border-left"
                    css={css`
                      margin: ${hidden ? '0' : '20px 0'};
                      height: ${hidden ? 0 : 'auto'};
                      z-index: ${hidden ? -100 : 0};
                      transition: opacity 1s;
                      opacity: ${hidden ? 0 : 1};
                  `}
                  >
                    <div
                      css={css`
                      `}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: html }}
                      ></div>
                      <div
                        className="text-gray"
                        css={css`
                          font-weight: 600;
                        `}
                      >
                        <i
                          className="material-icons inline-icon"
                          css={css`
                            margin-right: 5px;
                          `}
                        >
                          access_time
                        </i>
                        <span
                          css={css`
                            vertical-align: middle;
                          `}
                        >
                          {date}
                        </span>
                      </div>

                    </div>
                  </Col>
                )
              })
            })()}
          </Row>
        </Container>
        <div
          css={css`
            text-align: center;
          `}
        >
          {
            (numNews < maxNews) && (
              <Button
                variant="link"
                onClick={() => {
                  setNumNews(Math.min(numNews + 4, maxNews));
                  document.activeElement.blur();
                }}
              >
                もっと見る
              </Button>
            )
          }
        </div>
      </PageContent>

      {[
        ["soundtope", "/soundtope-cover.jpg"],
        ["omnion", "/omnion-cover.jpg"],
        ["sonicwalk", "/sonicwalk-cover.jpg"],
        ["mimicafe", "/mimicafe-cover.jpg"],
      ].map(([title, image]) => (
        <Link to={`/technology/${title}`} key={title}>
          <div
            css={css`
              width: 100%;
              height: 150px;
              &:hover {
                opacity: 0.8;
              }
              ${bp.xs} {
                height: 250px;
              }
              ${bp.sm} {
                height: 350px;
              }
            `}
          >
            {/* <OverlayGreen css={css`
                height: 150px;
                ${bp.xs} {
                  height: 250px;
                }
                ${bp.sm} {
                  height: 350px;
                }
              `}/> */}
            <div
              css={css`
                position: absolute;
                width: 100%;
                color: white;
                font-weight: 600;
                font-size: 55px;
                text-align: center;
                line-height: 150px;
                background-position: center top;
                background-size: cover;
                ${bp.xs} {
                  font-size: 102px;
                  line-height: 250px;
                }
                ${bp.sm} {
                  font-size: 150px;
                  line-height: 350px;
                }
              `}
              style={{ backgroundImage: `url(${image})` }}
            >
              {title}
            </div>
          </div>
        </Link>
      ))}

      <PageContent>
        <h2
          css={css`
            margin-top: 40px;
            margin-bottom: 35px;
          `}
        >
          keywords
        </h2>
        <div
          css={css`
            font-size: 44px;
            font-weight: 600;
            ${bp.xs} {
              font-size: 75px;
            }
            ${bp.sm} {
              font-size: 44px;
            }
          `}
        >
          {keywordTitles
            .map((word, i) => (
              <span
                css={css`
                  cursor: pointer;
                  &:hover {
                    color: #00b45a;
                  }
                `}
                key={i}
                onClick={() => {
                  setSelectedKeyword(word)
                  setModalShow(true)
                }}
              >
                {word}
              </span>
            ))
            .reduce(
              (stack, element, i) =>
                stack === null
                  ? [element]
                  : [...stack, <span key={`separator-${i}`}>／</span>, element],
              null
            )}
        </div>
      </PageContent>

      <KeywordModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        keyword={selectedKeyword}
        article={keywordArticles[selectedKeyword]}
      />

      <div style={{ width: "100%" }}>
        <div style={{ width: "85%", margin: "0 auto" }}>
          <a
            className="twitter-timeline tw-align-center"
            data-width="100%"
            data-height="1000"
            data-chrome="noheadernofooternoborders"
            href="https://twitter.com/coton_tech?ref_src=twsrc%5Etfw"
          >
            Tweets by coton_tech
          </a>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allNewsCsv(sort: { fields: date, order: DESC }) {
      nodes {
        date
        html
      }
      totalCount
    }
    markdownRemark(fileAbsolutePath: { regex: "//pages/keywords.md/" }) {
      htmlAst
    }
  }
`;
